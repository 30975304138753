<template>
  <b-card-code title="微信列表">
    <!-- search input -->

    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-login
      variant="outline-primary"
    >
      添加微信
    </b-button>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-login
      variant="outline-primary"
    >
      设置客服
    </b-button>
    <message></message>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="添加"
      cancel-title="关闭"
      centered
      no-close-on-backdrop
      title="添加微信设备"
      @ok="alert"
    >
      <b-form>
        <b-form-group>
          <label for="module">设备编号:</label>
          <b-form-input id="module" placeholder="设备编号" v-model="module1" />
        </b-form-group>
        <b-form-group>
          <label for="deviceid">设备串号:</label>
          <b-form-input
            id="deviceid"
            placeholder="设备串号"
            v-model="deviceid"
          />
        </b-form-group>
        <b-form-group label="部门名称" label-for="dname">
          <b-form-select
            v-model="roleSelect"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="roleOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            input-id="user-dname"
            @input="changeAcc"
          />
        </b-form-group>
        <b-form-group label="客服名称" label-for="nickname">
          <b-form-select
            v-model="accountSelect"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="accountOptions"
            :reduce="(val) => val.label"
            :clearable="true"
            input-id="user-nickname"
            @input="indexSelect"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="搜索"
            type="text"
            class="d-inline-block"
            @input="handleSearch"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      @on-sort-change="onSortChange"
      @on-selected-rows-change="selectionChanged"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'wechatnick'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span class="text-nowrap">{{ props.row.wechatnick }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'isonline'">
          <b-badge :variant="statusVariant(props.row.isonline)">
            {{ props.row.isonline == "0" ? "在线" : "离线" }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'createTime'">
          {{ timestampToTime(props.row.createTime) }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="golink(props.row.id)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>编辑</span>
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.modal-xl>
                <feather-icon icon="MessageCircleIcon" class="mr-50" />
                <span>微信记录</span>
              </b-dropdown-item>
              <b-dropdown-item @click="gochatroom(props.row.wechatid)">
                <feather-icon icon="UsersIcon" class="mr-50" />
                <span>群管理</span>
              </b-dropdown-item>
              <b-dropdown-item @click="updatefriend(props.row.wechatid)">
                <feather-icon icon="UsersIcon" class="mr-50" />
                <span>更新好友</span>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmText(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>删除</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> 显示 </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ total }} 条 </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BModal,
  VBModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
import message from "./WechatMessages";
export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    vSelect,
    BForm,
    BModal,
    VBModal,
    message,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      total: 0,
      cid: 0,
      desc: "",
      deviceid: "",
      module1: "",
      selectid: [],
      search: "",
      roleSelect: null,
      roleOptions: [],
      accountSelect: null,
      accountOptions: [],
      accountid: "",
      dir: false,
      pages: ["10", "20", "40", "100"],
      columns: [
        {
          label: "微信昵称",
          field: "wechatnick",
        },
        {
          label: "微信id",
          field: "wechatid",
        },
        {
          label: "微信号",
          field: "wechatno",
        },
        {
          label: "好友数量",
          field: "friendcount",
        },
        {
          label: "设备号",
          field: "module",
        },
        {
          label: "客服人员",
          field: "nickname",
        },
        {
          label: "在线状态",
          field: "isonline",
        },
        {
          label: "创建时间",
          field: "createTime",
        },
        {
          label: "操作",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: "light-success",
        1: "light-danger",

        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    useJwt
      .queryDevices({
        cid: this.cid,
        page: 1,
        rows: this.pageLength,
        desc: this.desc,
      })
      .then((res) => {
        // pagelength 一页显示多少条
        this.total = res.data.data.records;
        this.rows = res.data.data.rows;
      });
  },
  methods: {
    timestampToTime(cjsj) {
      const date = new Date(cjsj); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = `${date.getFullYear()}-`;
      const M = `${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-`;
      const D = `${
        date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
      } `;
      const h = `${
        date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
      }:`;
      const m = `${
        date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
      }:`;
      const s =
        date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
      return Y + M + D + h + m + s;
    },
    selectionChanged(params) {
      this.selectid = params.selectedRows;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.selectedRows.length}`,
          icon: "UserIcon",
          variant: "success",
        },
      });
    },
    golink(id) {
      this.$router.push({ name: "WechatEdit", params: { id } });
    },
    gochatroom(wechatid) {
      this.$router.push({ name: "ChatRoomManger", params: { wechatid } });
    },
    indexSelect(value) {
      let obj = {};

      obj = this.accountOptions.find((item) => item.value === value);
      if (obj != null) {
        this.accountid = obj.value;
      }
    },
    updatefriend(wechatid) {
      console.log(wechatid);
      useJwt.friendquery({ wechatid: wechatid }).then((res) => {
        this.$swal({
          icon: "success",
          title: "更新中!",
          text: "等待更新中..",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      });
    },
    chatroomlist(wechatid) {},
    changeAcc(newVal) {
      this.accountOptions = [];
      this.did = newVal;
      useJwt
        .getAllAccountInfoByTypeCidDid({ cid: this.cid, did: newVal })
        .then((response) => {
          const forArr = response.data.data;
          forArr.forEach((item, i) => {
            this.accountOptions.push({ text: item.nickname, value: item.id });
          });
          this.accountSelect = 0;
        });
    },
    confirmText(id) {
      this.$swal({
        title: "确定要删除吗?",
        text: "删除后无法恢复!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          useJwt.deleteDevices({ ids: id }).then((res1) => {
            if (res1.data.code === 0) {
              this.$swal({
                icon: "success",
                title: "删除!",
                text: "删除成功",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              useJwt
                .queryDevices({
                  cid: this.cid,
                  page: 1,
                  rows: this.pageLength,
                })
                .then((res2) => {
                  this.total = res2.data.data.records;
                  this.rows = res2.data.data.rows;
                });
            } else {
              this.$swal({
                icon: "error",
                title: "删除失败!",
                text: "请联系管理员处理",
                customClass: {
                  confirmButton: "btn btn-error",
                },
              });
            }
          });
        }
      });
    },
    alert() {
      useJwt
        .addDevice({
          cid: this.cid,
          deviceid: this.deviceid,
          accountid: this.accountid,
          devnickname: this.devnickname,
          brand: "",
          module: this.module1,
        })
        .then((res) => {
          console.log("提交结果", res);
          if (res.data.code == 0) {
            this.$swal({
              icon: "success",
              title: "添加设备成功!",
              text: "添加成功",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
    },
    handleSearch(searching) {
      useJwt
        .queryDevices({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          wechatnick: searching,
        })
        .then((res) => {
          this.total = res.data.data.records;
          if (this.total == 0) {
            useJwt
              .queryDevices({
                cid: this.cid,
                page: 1,
                rows: this.pageLength,
                wechatid: searching,
              })
              .then((res) => {
                this.total = res.data.data.records;
                if (this.total == 0) {
                  useJwt
                    .queryDevices({
                      cid: this.cid,
                      page: 1,
                      rows: this.pageLength,
                      wechatno: searching,
                    })
                    .then((res) => {
                      this.total = res.data.data.records;
                      if (this.total == 0) {
                        useJwt
                          .queryDevices({
                            cid: this.cid,
                            page: 1,
                            rows: this.pageLength,
                            module: searching,
                          })
                          .then((res) => {
                            this.total = res.data.data.records;
                            this.rows = res.data.data.rows;
                          });
                      }
                      this.rows = res.data.data.rows;
                    });
                }
                this.rows = res.data.data.rows;
              });
          }
          this.rows = res.data.data.rows;
        });

      this.search = searching;
    },
    handleChangePage(page) {
      useJwt
        .queryDevices({
          cid: this.cid,
          page: page,
          rows: this.pageLength,
          desc: this.desc,
          wechatnick: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
    handlePageChange(active) {
      useJwt
        .queryDevices({
          cid: this.cid,
          page: 1,
          rows: active,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
         
          this.pageLength = active;
          this.rows = res.data.data.rows;
          this.total = res.data.data.records;
        });
    },
    onSortChange(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }

      useJwt
        .queryDevices({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
  },
  mounted() {
    useJwt.queryByCid({ cid: this.cid }).then((response) => {
      if (response.data.code == 0) {
        const forArr = response.data.data;

        forArr.forEach((item, i) => {
          this.roleOptions.push({ text: item.name, value: item.id });
        });
      }
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
